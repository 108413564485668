import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ProfileManager from './components/ProfileManager';
import ConversationList from './components/ConversationList';
import ConversationHandler from './components/ConversationHandler';
import ProjectList from './components/ProjectList';
import InputField from './components/InputField';
import api from './api';
import ConversationContainer from './components/ConversationContainer';
import TaskManager from './components/TaskManager';

const App = () => {
  const [date, setDate] = useState(new Date());
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [error, setError] = useState(null);
  const [conversationId, setConversationId] = useState(() => {
  const storedConversationId = localStorage.getItem('conversationId');
    return storedConversationId || uuidv4();
  });
  const [conversations, setConversations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [profileId, setProfileId] = useState(() => {
    const storedProfileId = localStorage.getItem('profileId');
    return storedProfileId || "";
  });
  const [isStreaming, setIsStreaming] = useState(false);
  const [createImage, setCreateImage] = useState(false);
  const [createAudio, setCreateAudio] = useState(false)
  const [tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState({});
  const [mounted, setMounted] = useState(false);
  const [document, setDocument] = useState(null);
  const [docSearch, setDocSearch] = useState(false);
  const [webSearch, setWebSearch] = useState(false);
  const [shopGoodwill, setShopGoodwill] = useState(false);
  const [keywords, setKeywords] = useState('')
  const [userName, setUserName] = useState('');
  const [selectedModel, setSelectedModel] = useState(() => {
    const storedSelectedModel = localStorage.getItem('selectedModel');
    return storedSelectedModel || 'llama4'; // default to 'llama4' if no stored value is found
  });
  const [models, setModels] = useState([]);


  const taskManagerProps = {
    tasks: tasks,
    setTasks: setTasks,
    setCurrentTask: setCurrentTask
  };

  useEffect(() => {
    const loadConversations = async () => {
      try {
        const response = await api.conversation.getConversations();
        setConversations(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    const loadProfiles = async () => {
      try {
        const response = await api.profiles.getProfiles();
        setProfiles(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    const loadTasks = async () => {
      try {
        const response = await api.tasks.getTasks();
        setTasks(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    const loadModels = async () => {
      try {
        const response = await api.conversation.getAiModels(true, false, false);
        setModels(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    setUserName(localStorage.getItem('userName'));
    loadConversations();
    loadProfiles();
    loadTasks();
    loadModels();
    setMounted(true);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedModel', selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    localStorage.setItem('profileId', profileId);
  }, [profileId]);  

  useEffect(() => {
    const loadConversation = async () => {
      try {
        setDate(new Date());
        const response = await api.conversation.getConversation(conversationId);
        if (response.data.length > 0) {
          // Parse messages if they are returned as a string
          try {
            const parsedMessages = JSON.parse(response.data[0].messages);
            setConversation(parsedMessages);
          } catch (err) {
            setConversation(response.data[0].messages);
          }
        
        } else {
          // If no conversation data is found, initialize with a default message
          const profile = profiles.find((profile) => profile.id === profileId);
          if (profile) {
            const systemMessage = `Name: ${profile.name} \
                      Role: Friendly, loyal and detailed personal assistant to the user \
                      Personality Traits: \
                      ${profile.description} \
                      Today Date: ${date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}`;
            setConversation([
              { role: 'system', content: systemMessage }
            ]);
          } else {
            setConversation([
              { role: 'system', content: `You are a friendly AI assistant.` }
            ]);
          }
        }
      } catch (err) {
        setError(err.message);
      }
    };
    localStorage.setItem('conversationId', conversationId);
    loadConversation();
  }, [conversationId]);  

  useEffect(() => {
    if (mounted && Object.keys(currentTask).length > 0 && currentTask.requestData && (currentTask.requestData.prompt || currentTask.requestData.text)) {
      setMessage(currentTask.requestData.prompt);
      if (currentTask.type === `create image`) {
        setCreateImage(true)
      } else if (currentTask.type === `doc search` && currentTask.requestData.prompt && currentTask.requestData.prompt.length > 1) {
        setMessage(currentTask.requestData.prompt);
        setDocSearch(true)
      } else if (currentTask.type === `shop goodwill` && currentTask.requestData.prompt && currentTask.requestData.prompt.length > 1) {
        setMessage(currentTask.requestData.prompt);
        setKeywords(currentTask.requestData.keywords)
        setShopGoodwill(true)
      } else if (currentTask.type === `web search` && currentTask.requestData.prompt && currentTask.requestData.prompt.length > 1) {
        setMessage(currentTask.requestData.prompt);
        setWebSearch(true)
      } else if (currentTask.type === `process document` && currentTask.requestData.text && currentTask.requestData.text.length > 1) {
        setMessage(`Process Document`);
        setDocument({ id: currentTask.requestData.id, text: currentTask.requestData.text})
      } else if (currentTask.type === `process image` && currentTask.requestData.image && currentTask.requestData.image.length > 1) {
        setImage(currentTask.requestData.image)
      }
    }
  }, [currentTask, mounted]);
    
  const handleConversationSelect = (id) => {
    setConversationId(id);
  };

  const handleDeleteConversation = (conversationId) => {
    try {
      api.conversation.deleteConversation(conversationId).then(() => {
        setConversations(conversations.filter((c) => c.id !== conversationId));
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteMessage = (deletedMessage) => {
    setConversation((prevConversation) =>
      prevConversation.filter((message) => message !== deletedMessage)
    );
  };

  const handleSpeechMessage = (item) => {
    const text = item.content.replace(/```[\s\S]*?```/g, ' ');
    api.conversation.textToSpeech(text).then((audioBlob) => {
      const audioUrl = URL.createObjectURL(new Blob([audioBlob], { type: 'audio/mpeg' }));
      const audioElement = new Audio(audioUrl);
      audioElement.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    }).catch((error) => {
      console.error('Error getting audio:', error);
    });
  };  

  const callTool = async (toolCalls) => { 
    const toolResponse = await Promise.all(
      toolCalls.map(async (toolCall) => { 
        const { name } = toolCall; 
        switch (name) {
           case 'image_generator': 
            const imageResponse = await api.conversation.createImage(toolCall.arguments.prompt); 
            return `Image generated: ${imageResponse.data.image}`; 
           default: 
            throw new Error(`Unknown tool: ${name}`); 
          } 
      })); 
    return toolResponse.join(' ');
  };

  const conversationHandler = ConversationHandler({
    conversation,
    setConversation,
    conversationId,
    setConversationId,
    message,
    setMessage,
    image,
    setImage,
    isSubmitting,
    setIsSubmitting,
    isStreaming,
    setIsStreaming,
    handleDeleteMessage,
    setCreateImage,
    createImage,
    document,
    setDocument,
    docSearch,
    setDocSearch,
    webSearch,
    setWebSearch,
    shopGoodwill,
    setShopGoodwill,
    currentTask,
    setCurrentTask,
    keywords,
    setKeywords,
    selectedModel
  });

  return (
    <div className="main-content">
      <div className="header">
        <div className="header-left">
          <img src="/favicon.ico" alt="Logo" className="logo" />
          <span>Autodevops.net</span>
        </div>
        <div className="header-right">
          <span>Welcome, {userName}</span>
          <button className="logout-button" onClick={async () => {
              try {
                //await api.logout(); // Call your API's logout endpoint
                localStorage.clear();
                window.location.href = '/login';
              } catch (error) {
                console.error('Error logging out:', error);
              }
            }}>Logout</button>
        </div>
      </div>

      <div className="container">
        <div className="left-column">
          <button className="button" onClick={conversationHandler.handleNewConversation}>New Conversation</button>
          <select value={profileId} onChange={(e) => setProfileId(e.target.value)}>
            <option value="">Select Profile</option>
            {profiles && profiles.map((profile) => (
              <option key={profile.id} value={profile.id}>
                {profile.name}
              </option>
            ))}
          </select>
          <ConversationList
            conversations={conversations}
            onSelect={handleConversationSelect}
            onDeleteConversation={handleDeleteConversation}
          />
          <ProfileManager
            profiles={profiles}
            setProfiles={setProfiles}
          />
          <TaskManager {...taskManagerProps} />
        </div>
        <div className="center-column">
          <div className="conversation-wrapper">
              <ConversationContainer 
                conversation={conversation}
                onDeleteMessage={handleDeleteMessage}
                onSpeechMessage={handleSpeechMessage}
              />
              {isStreaming && <span>Streaming response...</span>}
          </div>
        </div>
        <div className="right-column">
          <ProjectList
            setMessage={setMessage}
            message={message}
          />
        </div>
      </div>
      <div className="footer">
        <InputField
          handleSubmit={conversationHandler.handleSubmit}
          message={message}
          setMessage={setMessage}
          isSubmitting={isSubmitting}
          onImageLoaded={conversationHandler.handleImageLoaded}
          setCreateImage={setCreateImage}
          createImage={createImage}
          setCreateAudio={setCreateAudio} 
          createAudio={createAudio}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          models={models}
        />
        &copy; Autodevops.net
      </div>
    </div>
  );
    
};

export default App;
