// components/InputField.js
import React, { useState, useEffect } from 'react';
import ImageUploader from './ImageUploader';

const InputField = ({ 
  handleSubmit, 
  message, 
  setMessage, 
  isSubmitting, 
  onImageLoaded, 
  setCreateImage, 
  createImage, 
  setCreateAudio, 
  createAudio ,
  selectedModel,
  setSelectedModel,
  models
}) => {

  return (
    <div className="input-container">
      <textarea
        className="textarea"
        placeholder="Enter your message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <ImageUploader onImageLoaded={(base64String) => onImageLoaded(base64String)} />
      <input
        type="checkbox"
        checked={createImage}
        onClick={(e) => setCreateImage(!createImage)}
      />
      <label>Create Image</label>
      <input
        type="checkbox"
        checked={createAudio}
        onClick={(e) => setCreateAudio(!createAudio)}
      />
      <label>Create Audio</label>
      <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
        {models.map((model) => (
          <option key={model.model_name} value={model.model_name}>
            {model.model_name}
          </option>
        ))}
      </select>
      <button
        className="button"
        onClick={handleSubmit}
        disabled={isSubmitting} // Disable the button while submitting
      >
        {isSubmitting ? 'Processing...' : 'Submit'}
      </button>
    </div>
  );
};

export default InputField;
